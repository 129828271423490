import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components

function SectionHeader() {
  return (
    <>
      <div className="section section-feature" id="team">
        <div className="text-center">
          <h2 className="title title-uppercase">Team</h2>
        </div>
  
        {/* ********* FEATURES 3 ********* */}
        <div className="">
      
          <Container>
          
            <Row>
              <Col md="6">
                <Row>
                  <Container>
                    <h2 className="title ">Kate Paradise</h2>
                    <h5 className="title-uppercase">PRINCIPAL</h5>
                  </Container>
                </Row>
                <Row>
                  <Col>
                    <h5 className="bio-text" >Kate Paradise has wide-ranging experience as a transactional real estate attorney, representing lenders and borrowers in a variety of real estate matters, including mortgage and mezzanine financing, bridge and construction loans, and credit facilities. Kate has developed significant experience in commercial and multifamily acquisitions and dispositions, and representing borrowers in obtaining loans from commercial mortgage-backed security (CMBS) lenders and other lenders, including mortgages sold to the Federal Home Loan Mortgage Corporation (Freddie Mac) and Fannie Mae.
                    </h5>
                    <h5>Kate’s practice focuses on rendering legal opinions in connection with complex real estate transactions, including opinions under laws of California, Delaware, the District of Columbia, Illinois, Maryland, and New York. Kate also provides substantive non-consolidation opinion letters for individual and portfolio real estate loans.
                    <br /><br />
                      Kate (Kathryn) Paradise is licensed to practice law in California, Delaware, the District of Columbia, Illinois, Maryland, and New York.
                </h5>
                  </Col>
                </Row>
              </Col>
              <Col className="ml-auto" md="5">
                <div className="iphone-container">
                  <img
                    className="img-circle"
                    alt="..."
                    style={{ width: '300px', marginTop: '35px' }}
                    src={require("assets/img/kate_headshot.jpeg")}
                  />
                </div>
                <div style={{ padding: '13px' }}>
                  <h4>Education</h4>
                  <ul className="awards">
                    <li>University of Southern California Gould School of Law <br /> J.D., 2008</li>
                    <li>University of California, Berkeley<br /> B.A., 2005</li>
                  </ul>

                  <h4>Awards & Recognition</h4>
                  <ul className="awards">
                    <li>Super Lawyers 2019-2022</li>
                    <li>Southern California Rising Stars 2015-2018</li>
                  </ul>

                  <h4>Bar Admissions</h4>
                  <ul className="awards">
                    <li>California</li>
                    <li>Delaware</li>
                    <li>District of Columbia</li>
                    <li>Illinois</li>
                    <li>Maryland</li>
                    <li>New York</li>
                  </ul>
                </div>
              </Col>
            </Row>
            <hr></hr>

            <Row>
   

              <Col md="6">
                <Row>
                  <Container>
                    <h2 className="title ">Carolina Ivich</h2>
                    <h5 className="title-uppercase">Senior Counsel</h5>
                  </Container>
                </Row>
                <Row>
                  <Col>
                    <h5 className="bio-text">
                      Carolina’s practice focuses on rendering legal opinions in real estate finance transactions. Prior to joining the firm, Carolina practiced in the areas of real estate and corporate transactions, commercial leasing and representation of creditors in Bankruptcy Court. 
                    </h5>

                    <h5 className="bio-text">
                      Carolina has represented a range of clients, including entrepreneurs, franchisees, real estate developers and other companies and individuals in respect to the acquisition, sale, leasing and development of property, as well as providing guidance with the formation and maintenance of business entities.
                    </h5>
                
                    <h5 className="bio-text">
                    Carolina Ivich is licensed to practice law in Florida and Indiana.
                  </h5>

                  </Col>
                </Row>
              </Col>


              <Col className="ml-auto" md="5">
                <div className="iphone-container">
                  <img
                    className="img-circle"
                    alt="..."
                    style={{ width: '300px', marginTop: '35px' }}
                    src={require("assets/img/carolina_new_headshot.png")}
                  />
                </div>
                <div style={{ padding: '13px' }}>
                  <h4>Education</h4>
                  <ul className="awards">
                    <li>St. Thomas University School of Law <br /> J.D., 2008</li>
                    <li>The University of Arizona<br /> B.A., 2004</li>
                  </ul>

                  {/* <h4>Awards & Recognition</h4>
                  <ul className="awards">
                    <li>Super Lawyers 2019, 2020</li>
                    <li>Southern California Rising Stars 2015-2018</li>
                  </ul> */}

                  <h4>Bar Admissions</h4>
                  <ul className="awards">
                    <li>Florida</li>
                    <li>Indiana</li>
                  </ul>
                </div>
              </Col>

            </Row>
            <hr></hr>

            {/* End profile */}


            




            <Row>

              <Col md="12">
                <Row>
                  <Container>

                  </Container>
                </Row>


            <Row>
              <Col md="6">
                <Row>
                  <Container>
                    <h2 className="title">Rami Nabi</h2>
                    <h5 className="title-uppercase">Senior Counsel</h5>
                  </Container>
                </Row>
                <Row>
                  <Col>
                    <h5 className="bio-text">Rami’s practice focuses on rendering legal opinions in respect with complex real estate transactions.  Rami’s transactional and litigation experience encompasses multi-family real estate and commercial real estate ranging from industrial, office, restaurant, and shopping centers. Rami has represented individual owners, business entities, property management companies, landlords, tenants, and developers in a wide range of real estate transactions. </h5>
               
                  </Col>
                </Row>
              </Col>
              <Col className="ml-auto" md="5">
                <div className="iphone-container">
                      <img
                        className="img-circle"
                        alt="..."
                        style={{ width: '300px', marginTop: '35px' }}
                        src={require("assets/img/rami-headshot.png")}
                      />
                </div>
    
              </Col>

           
            </Row>
                <Row>
                  <Col md="4">
                    <div style={{ padding: '13px' }}>
                      <h4>Education</h4>
                      <ul className="awards">
                        <li>Chapman University Fowler School of Law  <br /> J.D., 2008</li>
                        <li>University of California, Santa Barbara <br /> B.A., 2004</li>
                      </ul>
                    </div>
           
  

                  </Col>
                  <Col md="4">
                    <div style={{ padding: '13px' }}>
                      <h4>Awards & Recognition</h4>
                      <ul className="awards">
                        <li>2022 Martindale Hubbell Client Champion Gold Distinction</li>
                        <li>2012 Martindale Hubbell Client Distinction Award</li>
                        <li>2011 Recognition of Pro Bono Volunteers in the United States Bankruptcy Court,
                          Central District of California</li>
                      </ul>
                    </div>

                  </Col>
                  <Col md="4">
                    <div style={{ padding: '13px' }}>
                      <h4>Bar Admissions</h4>
                      <ul className="awards">
                        <li>California</li>

                      </ul>
                    </div>

                  </Col>
                </Row>
            <hr></hr>


                <Row>

                  <Col className="ml-auto" md="6">
                    <h2 className="title">Susan Loveland</h2>
                    <h5 className="title-uppercase">PARALEGAL</h5>
                    <h5 className="bio-text">
                      Susan is a paralegal with 23 years of experience assisting attorneys, lenders and borrowers in many types of commercial real estate transactions. Susan’s strong attention to detail and organizational skills, along with her knowledge of various entity structures and vast background in working through complicated financing and acquisition closings, lend particularly well in assisting with legal opinions, tracking tasks/key dates and other related matters.
                    </h5>

                  </Col>

                  <Col className="ml-auto" md="6">
                    <div className="iphone-container text-center">
                      <img
                        className="img-circle"
                        alt="..."
                        style={{width: '300px',  marginTop: '35px'}}
                        src={require("assets/img/susan_new_headshot_square.png")}
                      />
                    </div>

                  </Col>
 
                </Row>
              </Col>

            </Row>
            <hr></hr>
            {/* End profile */}

            <Row>

<Col className="ml-auto" md="6">
  <h2 className="title">Jen Carroll</h2>
  <h5 className="title-uppercase">PARALEGAL</h5>
  <h5 className="bio-text">
  Jen is a well-versed paralegal with over 15 years of experience in complex commercial and residential real estate transactions. With a background as an asset and acquisition manager, property manager, and licensed agent in California and Colorado, Jen brings a wealth of knowledge to our firm. A graduate of the University of California, Irvine, with a Bachelor's Degree and Paralegal Certificate, Jen has previously worked at national law firms, supporting attorneys and clients in all aspects of real estate and corporate transactions. Jen's expertise in real estate law, finance, and project management, combined with strong attention to detail and organizational skills, makes her a valuable asset to our team and clients.

</h5>

</Col>

<Col className="ml-auto" md="6">
  <div className="iphone-container text-center">
    <img
      className="img-circle"
      alt="..."
      style={{width: '300px',  height:'300px', marginTop: '75px'}}
      src={require("assets/img/jen_headshot2.png")}
    />
  </div>

</Col>

</Row>

<hr></hr>

{/* End profile */}

            <Row>

              <Col md="12">
                <Row>
                  <Container>

                  </Container>
                </Row>
                <Row>
                  <Col className="ml-auto" md="6">
                    <h2 className="title">Chadd Bennett</h2>
                    <h5 className="title-uppercase">Executive Director</h5>
                    <h5 className="bio-text">Chadd is responsible for leading the law firm's operational and administrative functions, including finance, technology, human resources, and strategic planning. With over 20 years working in entrepreneurial roles, he has a long track record of successfully managing complex projects and executing on the most critical business objectives as they arise.

                      His passion for efficiency, combined with his technical understanding, work especially well with clients in transactional businesses with needs that demand high quality work and swift execution.</h5>

                  </Col>
                  <Col className="ml-auto" md="6">
                    <div className="iphone-container text-center">
                      <img
                        className="img-circle"
                        alt="..."
                        style={{ width: '300px', marginTop: '35px' }}
                        src={require("assets/img/chadd_headshot_2.png")}
                      />
                    </div>

                  </Col>
       
                </Row>
              </Col>

            </Row>       
            {/* End profile */}


          </Container>
      </div>
      {/* ********* END FEATURES 3 ********* */}

    </div>
    </>
  );
}

export default SectionHeader;
